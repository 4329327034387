// Footer.jsx
import React from 'react';

const Footer = () => {
  return (
    <div style={{
  
      padding: '1em',
      position: 'relative',
      bottom: '0',
      width: '100%',
      textAlign: 'center'
    }}>
      <p>© BoubouStudio 2023</p>
      <p>Conçu par Thomas Boursac</p>
    </div>
  );
};

export default Footer;
