import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import TransparentNavbar from './components/TransparentNavbar';
import Home from './home';
import MonCompte from './components/MonCompte';
import withAuth from './withAuth';
import Login from './login'; 
import Book from './book';
// ... autres imports ...
import './App.css';

function Content() {
  const location = useLocation();
  const AuthenticatedHome = withAuth(Home);
  const AuthenticatedMonCompte = withAuth(MonCompte);
  const AuthenticatedBook = withAuth(Book);

  return (
    <div>
      {location.pathname !== "/login" && <TransparentNavbar />}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<AuthenticatedHome />} />
        <Route path="/mon-compte" element={<AuthenticatedMonCompte />} />
        <Route path="/book" element={<AuthenticatedBook />} />
        {/* Autres routes ... */}
      </Routes>
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <Router>
        <Content />
      </Router>
    </div>
  );
}

export default App;
