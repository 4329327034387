import React, { useState, useEffect } from 'react';
import { db } from './firebase';  
import { onValue , ref} from 'firebase/database';
import BookModal from './components/ModalBook';
import Category from './components/Category';// Assurez-vous d'importer le composant Category correctement
import Footer from './components/footer';


const Book = () => {
const [book, setBook] = useState([]);
const [categories, setCategories] = useState([]);
const [modalInfo, setModalInfo] = useState(null);

useEffect(() => {
    console.log("helo Book");
    const fetchData = async () => {
        const dbRef = ref(db); // Reference to the root of your database

        onValue(dbRef, (snapshot) => {
            const dbData = snapshot.val();
            setCategories(dbData.books || []);
            
        });
    };

    fetchData();
}, []);

const openModal = (film) => {
    setModalInfo(film);
};

const closeModal = () => {
    setModalInfo(null);
};


    return (
        <div style={{ marginLeft: '20px', marginRight: '20px' }}>
            {categories.map((category, index) => (
                <Category 
                    key={index} 
                    title={category.name} 
                    films={category.films} 
                    onCardClick={openModal} 
                />
            ))}
            {modalInfo && <BookModal film={modalInfo} onClose={closeModal} />
            }
         <Footer/>
        </div>
    );
};

export default Book;
